export default {
  generic: {
    appName: 'Nectar',
    email: 'Email',
    otpCode: 'One-Time Code',
    password: 'Password',
    username: 'Username',
    firstname: 'First Name',
    lastname: 'Last Name',
    birthdate: 'Date of Birth',
    lang: 'Language',
    website: 'Website',
    logout: 'Logout',
    save: 'Save',
    height: 'Height',
    weight: 'Weight',
    sleep: 'Hours of Sleep',
    gender: {
      male: 'male',
      female: 'female',
    },
    day: '0 day | {count} day | {count} days',
    week: '0 week | {count} week | {count} weeks',
    years: '{years} year | {years} year | {years} years',
    updatedAt: 'Last updated: {date}',
    add: 'Add',
    foodSurvey:
      'no dietary survey | {count} dietary survey | {count} dietary surveys',
  },
  foodType: {
    base: 'Staple Food',
    brand: 'Commercial Product',
    recipe: 'Recipe',
  },
  foodConstraints: {
    is_fish_free: 'Fish-Free',
    is_gluten_free: 'Gluten-Free',
    is_lactose_free: 'Lactose-Free',
    is_pork_free: 'Contains Pork',
    is_meat_free: 'Meat-Free',
    is_vegan: 'Vegan-Friendly',
    is_vegetarian: 'Vegetarian-Friendly',
    is_added_sugar_free: 'Contains Added Sugars',
    is_fish_or_seafood_free: 'Fish and Seafood-Free',
  },
  lang: {
    fr: '🇫🇷 Français',
    en: '🇬🇧 English',
    pt: '🇵🇹 Portuguese',
  },
  units: {
    symbols: {
      ratio: 'Ratio',
      gram: 'g',
      milliliter: 'ml',
      centiliter: 'cl',
      milligram: 'mg',
      microgram: 'μg',
    },
  },
  food: {
    type: {
      industrial: 'Industrial Food',
      raw: 'Raw Food',
    },
  },
  nutrient: {
    type: {
      micro: 'micro',
      macro: 'macro',
      vitamin: 'vitamin',
      mineral: 'mineral',
      'fatty-acid': 'fatty acid',
      'amino-acid': 'amino acid',
      cholesterol: 'cholesterol',
      sugar: 'sugar',
    },
  },
  validations: {
    duplicate: 'This email already exists',
    email: 'Please enter a valid email address.',
    generic: 'This field is required.',
    invalidEmail:
      'Invalid format, the email format should be example@example.com',
    invalidUrl:
      'Invalid format, the URL format should be https://www.example.com',
    maxCharacters: 'max characters',
    picture: {
      dimensions:
        'The image is too wide, please select a file smaller than {maxSize}',
      size: 'The image is too large, please select a file smaller than',
      type: 'Invalid format, the image format should be *.jpg or *.png.',
    },
    required: 'This field is required.',
    url: 'Invalid format, the URL format should be https://www.example.com',
    valid: 'must be valid',
    minLength: 'This field must contain at least {min} characters.',
    minValue: 'This field must have a value greater than or equal to {min}.',
    sameAsPassword: 'The password must match.',
  },
  homePage: {
    heading: 'Welcome to Nectar, please sign in ☀️',
    cta: 'Sign In',
  },
  signInPage: {
    welcome: {
      heading: '✨ Welcome to Nectar',
      description:
        'The SaaS nutrition solution for health and wellness professionals',
    },
    heading: 'Login',
    cta: 'Sign In',
    form: {
      email: {
        placeholder: 'Your email address',
      },
      password: {
        placeholder: 'Your password',
      },
      otpCode: {
        placeholder: 'e.g., 111222',
      },
    },
  },
  dashboardPage: {
    heading: 'Dashboard',
    sidePanel: {
      menu: {
        home: {
          label: 'Home',
        },
        account: {
          label: 'My Account',
        },
        patient: {
          label: 'My Patients',
        },
      },
    },
    accountPage: {
      heading: 'My Account',
    },
  },
  page: {
    consultation: {
      objectives: {
        'weight-loss': 'Weight Loss',
        'gain-muscle-mass': 'Muscle Mass Gain',
        'improved-physical-performance': 'Improved Physical Performance',
        'improved-energy-and-vitality': 'Improved Energy and Vitality',
        'management-of-medical-conditions': 'Management of Medical Conditions',
        'digestive-health': 'Digestive Health',
        'transition-to-specific-diet': 'Transition to a Specific Diet',
        'nutrition-education': 'Nutrition Education',
      },
    },
  },
  modal: {
    foodUpsert: {
      create: {
        title: 'Create Food',
      },
      update: {
        title: 'Update Food',
      },
    },
    patientUpsert: {
      create: {
        title: 'Create Patient',
      },
      update: {
        title: 'Update Patient',
      },
    },
    portionUpsert: {
      create: {
        title: 'Create Portion',
      },
      update: {
        title: 'Update Portion',
      },
    },
  },
  onboarding: {
    patient: {
      regime: {
        isVegan: 'Vegan',
        isVegetarian: 'Vegetarian',
        isPorkFree: 'Pork-Free',
        isFishFree: 'Fish and Seafood-Free',
        isSugarFree: 'Sugar-Free',
        isGlutenFree: 'Gluten-Free',
        isLactoseFree: 'Lactose-Free',
      },
    },
  },
  recommendation: {
    fixedAge: 'Recommendation for {gender} aged {age}',
    rangeAge: 'Recommendation for {gender} aged between {minAge} and {maxAge}',
  },
};
