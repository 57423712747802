export default {
  generic: {
    appName: 'Nectar',
    email: 'Email',
    otpCode: 'Código de Uso Único',
    password: 'Senha',
    username: 'Nome de usuário',
    firstname: 'Nome',
    lastname: 'Sobrenome',
    birthdate: 'Data de nascimento',
    lang: 'Idioma',
    website: 'Site',
    logout: 'Sair',
    save: 'Salvar',
    height: 'Altura',
    weight: 'Peso',
    sleep: 'Horas de sono',
    gender: {
      male: 'masculino',
      female: 'feminino',
    },
    day: '0 dia | {count} dia | {count} dias',
    week: '0 semana | {count} semana | {count} semanas',
    years: '{years} ano | {years} ano | {years} anos',
    updatedAt: 'Última atualização: {date}',
    add: 'Adicionar',
    foodSurvey:
      'nenhuma pesquisa alimentar | {count} pesquisa alimentar | {count} pesquisas alimentares',
  },
  foodType: {
    base: 'Alimento básico',
    brand: 'Produto comercial',
    recipe: 'Receita',
  },
  foodConstraints: {
    is_fish_free: 'Sem peixe',
    is_gluten_free: 'Sem glúten',
    is_lactose_free: 'Sem lactose',
    is_pork_free: 'Contém porco',
    is_meat_free: 'Sem carne',
    is_vegan: 'Adequado para veganos',
    is_vegetarian: 'Adequado para vegetarianos',
    is_added_sugar_free: 'Contém açúcares adicionados',
    is_fish_or_seafood_free: 'Sem peixe ou frutos do mar',
  },
  lang: {
    fr: '🇫🇷 Français',
    en: '🇬🇧 English',
    pt: '🇵🇹 Português',
  },
  units: {
    symbols: {
      ratio: 'Proporção',
      gram: 'g',
      milliliter: 'ml',
      centiliter: 'cl',
      milligram: 'mg',
      microgram: 'μg',
    },
  },
  food: {
    type: {
      industrial: 'Alimento industrializado',
      raw: 'Alimento cru',
    },
  },
  nutrient: {
    type: {
      micro: 'micro',
      macro: 'macro',
      vitamin: 'vitamina',
      mineral: 'mineral',
      'fatty-acid': 'ácido graxo',
      'amino-acid': 'aminoácido',
      cholesterol: 'colesterol',
      sugar: 'açúcar',
    },
  },
  validations: {
    duplicate: 'Este email já existe',
    email: 'Por favor, insira um email válido.',
    generic: 'Este campo é obrigatório.',
    invalidEmail:
      'Formato inválido, o email deve estar no formato exemplo@exemplo.com',
    invalidUrl:
      'Formato inválido, a URL deve estar no formato https://www.exemplo.com',
    maxCharacters: 'caracteres no máximo',
    picture: {
      dimensions:
        'A imagem é muito larga, selecione um arquivo menor que {maxSize}',
      size: 'A imagem é muito grande, selecione um arquivo menor que',
      type: 'Formato inválido, a imagem deve estar em formato *.jpg ou *.png.',
    },
    required: 'Este campo é obrigatório.',
    url: 'Formato inválido, a URL deve estar no formato https://www.exemplo.com',
    valid: 'deve ser válido',
    minLength: 'Este campo deve conter pelo menos {min} caracteres.',
    minValue: 'Este campo deve ter um valor maior ou igual a {min}.',
    sameAsPassword: 'A senha deve coincidir.',
  },
  homePage: {
    heading: 'Bem-vindo ao Nectar, por favor, faça login ☀️',
    cta: 'Fazer login',
  },
  signInPage: {
    welcome: {
      heading: '✨ Bem-vindo ao Nectar',
      description:
        'A solução SaaS de nutrição para profissionais de saúde e bem-estar',
    },
    heading: 'Entrar',
    cta: 'Fazer login',
    form: {
      email: {
        placeholder: 'Seu endereço de email',
      },
      password: {
        placeholder: 'Sua senha',
      },
      otpCode: {
        placeholder: 'ex.: 111222',
      },
    },
  },
  dashboardPage: {
    heading: 'Painel',
    sidePanel: {
      menu: {
        home: {
          label: 'Início',
        },
        account: {
          label: 'Minha conta',
        },
        patient: {
          label: 'Meus pacientes',
        },
      },
    },
    accountPage: {
      heading: 'Minha conta',
    },
  },
  page: {
    consultation: {
      objectives: {
        'weight-loss': 'Perda de peso',
        'gain-muscle-mass': 'Ganhar massa muscular',
        'improved-physical-performance': 'Melhoria no desempenho físico',
        'improved-energy-and-vitality': 'Melhoria na energia e vitalidade',
        'management-of-medical-conditions': 'Gestão de condições médicas',
        'digestive-health': 'Saúde digestiva',
        'transition-to-specific-diet': 'Transição para uma dieta específica',
        'nutrition-education': 'Educação nutricional',
      },
    },
  },
  modal: {
    foodUpsert: {
      create: {
        title: 'Criar alimento',
      },
      update: {
        title: 'Atualizar alimento',
      },
    },
    patientUpsert: {
      create: {
        title: 'Criar paciente',
      },
      update: {
        title: 'Atualizar paciente',
      },
    },
    portionUpsert: {
      create: {
        title: 'Criar porção',
      },
      update: {
        title: 'Atualizar porção',
      },
    },
  },
  onboarding: {
    patient: {
      regime: {
        isVegan: 'Vegano',
        isVegetarian: 'Vegetariano',
        isPorkFree: 'Sem porco',
        isFishFree: 'Sem peixe ou frutos do mar',
        isSugarFree: 'Sem açúcar',
        isGlutenFree: 'Sem glúten',
        isLactoseFree: 'Sem lactose',
      },
    },
  },
  recommendation: {
    fixedAge: 'Recomendação para {gender} com {age} anos',
    rangeAge: 'Recomendação para {gender} entre {minAge} e {maxAge} anos',
  },
};
